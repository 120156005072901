import {
	Button,
	Errors,
	Field,
	FieldDescription,
	FieldErrors,
	Heading,
	Input,
	Label,
	Option,
	RadioBar,
	RadioBarButton,
	RadioGroup,
	Select,
	TextField,
	TextLink,
	Container,
	Breadcrumbs,
	BreadcrumbItem,
	Section,
	Page,
	Form,
} from '@troon/ui';
import { query, createAsync, useLocation, useSubmission } from '@solidjs/router';
import { createSignal, For, Match, Show, Suspense, Switch } from 'solid-js';
import dayjs from '@troon/dayjs';
import { Icon } from '@troon/icons';
import { Title } from '@solidjs/meta';
import { useUser } from '../../../providers/user';
import { Grid, GridFive, GridSeven } from '../../../components/layouts/grid';
import { createDollarFormatter } from '../../../modules/number-formatting';
import { gql, mutationAction, useMutation } from '../../../graphql';
import { cachedQuery } from '../../../graphql/cached-get';
import { dateToDayTime } from '../../../modules/date-formatting';
import type { Data } from './api/(api)';

export default function RequestATeeTime() {
	const user = useUser();
	const [guests, setGuests] = createSignal(0);
	const [members, setMembers] = createSignal(0);
	const [desiredClub, setDesiredClub] = createSignal<string | null>(null);
	const loc = useLocation();
	const data = createAsync(() => getFacilities({}), { deferStream: false });
	const makeRequest = useMutation(mutation);
	const submission = useSubmission(makeRequest);

	const dataTable = createAsync(() => getDataTable(desiredClub()));
	const dollarFormatter = createDollarFormatter();

	return (
		<>
			<Container>
				<Title>Request a Tee Time | Troon Privé Member Programs | Troon</Title>
				<div class="pt-4">
					<Breadcrumbs>
						<BreadcrumbItem href="/member-programs">Troon Privé Privileges</BreadcrumbItem>
						<BreadcrumbItem href="/member-programs/private-club-golf">Private Club Golf</BreadcrumbItem>
						<BreadcrumbItem href={loc.pathname}>Request a tee time</BreadcrumbItem>
					</Breadcrumbs>
				</div>

				<Page>
					<Grid>
						<GridFive>
							<Section>
								<Icon name="golf-cart-custom" class="size-24" />
								<Heading as="h2">Request a tee time</Heading>

								<p>
									Please note that only qualified private club members may request tee times at participating private
									clubs within Troon Privé Privileges. If you have any questions about the program or are experiencing
									issues accessing these benefits, please contact your home club staff.
								</p>

								<p>
									For Visa Infinite Members seeking to request a tee time, please utilize the designated site{' '}
									<TextLink href="https://troongolfrewards.com/verify/visa-infinite?locale=us">US Cardholders</TextLink>{' '}
									or{' '}
									<TextLink href="https://offer.troongolfrewards.com/visainfiniteprivilege?language=en">
										Canada Cardholders
									</TextLink>
									.
								</p>
							</Section>
						</GridFive>

						<GridSeven class="rounded border border-neutral bg-neutral-100 p-4 md:p-8">
							<Switch>
								<Match when={submission.result?.data?.troonPriveRequest.ok}>
									<div class="container mx-auto flex max-w-xl flex-col items-center gap-6 text-center">
										<Icon name="circle-check-custom" class="text-8xl" />
										<Heading as="h1" size="h2">
											Request received!
										</Heading>
										<p class="text-lg">
											Thank you for submitting your request. We have received your Troon Privé member request to reserve
											a tee time at one of our participating private clubs. Your desired destination club will be in
											touch to confirm your reservation.
										</p>
									</div>
								</Match>
								<Match when>
									<Form action={makeRequest} document={requestMutation} class="flex flex-col gap-6">
										<div class="flex w-full flex-wrap gap-6">
											<TextField name="firstName" required class="grow">
												<Label>First name</Label>
												<Input autocomplete="given-name" value={user()?.me.firstName} />
											</TextField>

											<TextField name="lastName" required class="grow">
												<Label>Last name</Label>
												<Input autocomplete="family-name" value={user()?.me.lastName} />
											</TextField>
										</div>

										<TextField name="email" required>
											<Label>Email address</Label>
											<Input autocomplete="email" inputMode="email" value={user()?.me.email} />
										</TextField>

										<TextField name="phone" required>
											<Label>Phone number</Label>
											<Input autocomplete="tel" inputMode="tel" />
										</TextField>

										<Field name="homeFacilityId" required>
											<Label>Your home club</Label>
											<Select>
												<Option disabled selected class="hidden" />
												<Suspense>
													<For each={data()?.outgoing.facilities.sort((a, b) => compare(a.name, b.name))}>
														{(club) => <Option value={club.id}>{club.name}</Option>}
													</For>
												</Suspense>
											</Select>
											<FieldErrors />
										</Field>

										<TextField name="homeMembershipNumber" required>
											<Label>Home club membership #</Label>
											<Input />
										</TextField>

										<Field name="requestedFacilityId" required>
											<Label>Club you desire to play</Label>
											<Select
												onChange={(e) => {
													setDesiredClub(e.target.value);
												}}
											>
												<Option disabled selected class="hidden" />
												<Suspense>
													<For each={data()?.incoming.facilities.sort((a, b) => compare(a.name, b.name))}>
														{(club) => <Option value={club.id}>{club.name}</Option>}
													</For>
												</Suspense>
											</Select>
											<FieldErrors />
										</Field>

										<Suspense>
											<Show when={dataTable()}>
												{(data) => (
													<div class="flex flex-col gap-4 border-b border-neutral pb-4">
														<table class="w-full">
															<thead>
																<tr>
																	<th class="rounded-tl-md border border-neutral bg-brand-700 px-3 py-2 text-white">
																		Schedule
																	</th>
																	<th class="border border-neutral bg-brand-700 px-3 py-2 text-white">Days</th>
																	<th class="border border-neutral bg-brand-700 px-3 py-2 text-white">Rate</th>
																	<th class="rounded-tr-md border border-neutral bg-brand-700 px-3 py-2 text-white">
																		Guest fee
																	</th>
																</tr>
															</thead>
															<tbody>
																<For each={Object.entries(data().season)}>
																	{([season, d]) => (
																		<tr>
																			<td class="border border-neutral bg-white px-3 py-2">{d.months}</td>
																			<td class="border border-neutral bg-white px-3 py-2">{data().days}</td>
																			<td class="border border-neutral bg-white px-3 py-2">
																				<Show when={season === 'high'} fallback="$55">
																					$80
																				</Show>
																			</td>
																			<td class="border border-neutral bg-white px-3 py-2">
																				{typeof d.guestFee === 'string'
																					? d.guestFee
																					: dollarFormatter().format(d.guestFee)}
																			</td>
																		</tr>
																	)}
																</For>
															</tbody>
														</table>
														<For each={data().restrictions?.split(/\n+/g)}>{(restriction) => <p>{restriction}</p>}</For>
													</div>
												)}
											</Show>
										</Suspense>

										<TextField name="__dayTime" required>
											<Label>Date you desire to play</Label>
											<Input type="datetime-local" min={dayjs().format('YYYY-MM-DDThh:mm')} />
										</TextField>

										<RadioGroup name="__numMembers" onSelect={(value) => setMembers(parseInt(value, 10) - 1)}>
											<Label>Total number of members</Label>
											<RadioBar>
												<RadioBarButton value="1" checked>
													<Label>1</Label>
												</RadioBarButton>
												<RadioBarButton value="2">
													<Label>2</Label>
												</RadioBarButton>
												<RadioBarButton value="3">
													<Label>3</Label>
												</RadioBarButton>
												<RadioBarButton value="4">
													<Label>4</Label>
												</RadioBarButton>
											</RadioBar>
											<FieldDescription>
												Select the total number of members <em>including yourself</em>.
											</FieldDescription>
										</RadioGroup>

										<Show when={members() > 0}>
											<fieldset class="mb-8 flex flex-col gap-4 border-b border-neutral pb-8">
												<legend class="text-lg font-semibold">
													Please enter the first and last name of each of your accompanying members
												</legend>
												<For each={[...Array(members()).keys()]}>
													{(_, i) => (
														<TextField name="members">
															<Label>Accompanying member {i() + 1}</Label>
															<Input />
														</TextField>
													)}
												</For>
											</fieldset>
										</Show>

										<RadioGroup name="__numGuests" onSelect={(value) => setGuests(parseInt(value, 10))}>
											<Label>Total number of guests</Label>
											<RadioBar>
												<RadioBarButton value="0" checked>
													<Label>None</Label>
												</RadioBarButton>
												<RadioBarButton value="1">
													<Label>1</Label>
												</RadioBarButton>
												<RadioBarButton value="2">
													<Label>2</Label>
												</RadioBarButton>
												<RadioBarButton value="3">
													<Label>3</Label>
												</RadioBarButton>
											</RadioBar>
											<FieldDescription>Select the total number of non-member guests.</FieldDescription>
										</RadioGroup>

										<Show when={guests() > 0}>
											<fieldset class="mb-8 flex flex-col gap-4 border-b border-neutral pb-8">
												<legend class="text-lg font-semibold">
													Please enter the first and last name of each of your accompanying guests
												</legend>
												<For each={[...Array(guests()).keys()]}>
													{(_, i) => (
														<TextField name="guests">
															<Label>Accompanying guest {i() + 1}</Label>
															<Input />
														</TextField>
													)}
												</For>
											</fieldset>
										</Show>

										<TextField name="comments">
											<Label>Additional comments</Label>
											<Input multiline />
										</TextField>

										<Errors />

										<Button class="w-fit" type="submit">
											Submit
										</Button>
									</Form>
								</Match>
							</Switch>
						</GridSeven>
					</Grid>
				</Page>
			</Container>
		</>
	);
}

const requestMutation = gql(`mutation troonPriveRequest(
	$firstName: String!
	$lastName: String!
	$email: String!
	$phone: String!
	$homeFacilityId: String!
	$requestedFacilityId: String!
	$homeMembershipNumber: String!
	$dayTime: CalendarDayTimeInput!
	$members: [String!]
	$guests: [String!]
	$comments: String
) {
	troonPriveRequest(
		firstName: $firstName,
		lastName: $lastName,
		email: $email,
		phone: $phone,
		homeFacilityId: $homeFacilityId,
		requestedFacilityId: $requestedFacilityId,
		homeMembershipNumber: $homeMembershipNumber,
		dayTime: $dayTime,
		members: $members,
		guests: $guests,
		comments: $comments,
	) {
		ok
	}
}`);
const mutation = mutationAction(requestMutation, {
	track: {
		event: 'formSubmission',
		transform() {
			return { form: 'prive-tee-times' };
		},
	},
	transform: (data) => {
		const date = dateToDayTime(new Date(data.get('__dayTime') as string));
		return {
			dayTime: data.get('__dayTime') ? date : undefined,
			members: (data.getAll('members') ?? []) as Array<string>,
			guests: (data.getAll('guests') ?? []) as Array<string>,
		};
	},
});

const getDataTable = query(async (courseName?: string | null) => {
	if (!courseName) {
		return null;
	}
	const params = new URLSearchParams({ courseName });
	const res = await fetch(`/member-programs/private-club-golf/api?${params}`);
	if (!res.ok) {
		return null;
	}
	return res.json() as Promise<Data>;
}, 'prive-course-data-table');

const facilityQuery = gql(`query priveTeeTimeFacilities {
	outgoing: facilitiesV3(allowsOutgoingPriveRequests: true, sortBy: name) {
		facilities {
			id
			name
		}
	}
	incoming: facilitiesV3(allowsIncomingPriveRequests: true, sortBy: name) {
		facilities {
			id
			name
		}
	}
}`);

const compare = new Intl.Collator('en').compare;

const getFacilities = cachedQuery(facilityQuery, {});
